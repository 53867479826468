import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "./styles.scss"
import Gallery from "../components/gallery";
import Bedrooms from "../components/bedrooms";
//import {Link} from "gatsby"

const IndexPage = () => {

    const [ offset, setOffset ] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', parallaxShift);

        return () => {
            window.removeEventListener('scroll', parallaxShift);
        }
    }, [offset])

    const parallaxShift = () => {
        setOffset(window.pageYOffset)
    }

    const divStyle = {
        willChange: 'transform',
        height: 'calc(100vh - 80px)',
        transform: 'translateY(' + (offset/2) + 'px) translateZ(0px)',
        filter: 'brightness(' + Math.max(70, (100-(offset/15))) + '%)'
    }

    return (
        <Layout>
            <SEO title="Home"/>

            <div style={{ height: `calc(100vh - 80px)`, overflow: `hidden`, transform: `translateY(-80px) translateZ(0px)` }}>
                <div style={divStyle} offset={offset}>
                    <Image/>
                </div>
            </div>

            <div className="section content container is-fluid">
                <div className="columns">
                    <div className="column">
                        <h1 className="title">Luxurious, yet affordable, holiday homes</h1>
                        <p className="subtitle">6 guests · 3 bedrooms · 3 bathrooms</p>
                        <div className="bookingsync-booknow" data-account-id="9324" data-rental-id="104414,109037"></div>
                        <script type="text/javascript" src="https://www.bookingsync.com/api/v2/js/booknow.js" async defer></script>
                    </div>
                    <div className="column">
                        <p>Your luxurious, yet affordable holiday haven is set apart from all others on the water's edge of the magnificent Lake Wakitipu, and only 4 minutes drive from both Queenstown central and the Airport or Remarkables Park shopping centre.</p>
                        <p>The mighty Remarkables mountain range forms the backdrop to your immediate lake frontage. You will have all the luxuries and conveniences of a fully self contained, ultra modern apartment during your stay at Mantra Marina. There are plenty of grounds and gardens. All of our apartments and hotel rooms are light filled, spacious and private. In the apartments there is excellent inside/ outside flow between the large internal living space and lake facing balconies. All the comforts of a holiday home are provided, including king beds, luxurious soft furnishings, quality linen and personal amenities, etc. Additionally, onsite parking is provided free of charge.</p>
                        <p>Golfing, boating, wineries, walking trails and a host of adventure sports are just minutes away. All your local tours and transportation can be booked by us.</p>
                        <p>Your hosts are very keen for you to truly feel welcome and for you to enjoy good value, ultra modern, ultra clean accommodation during your stay at our independently operated apartment hotel.  We are confident you will become a return Guest!</p>
                    </div>
                </div>
            </div>

            <div className="section content container is-fluid">
                <Gallery/>
            </div>

            <hr/>

            <div className="section content container is-fluid">
                <h2>Sleeping arrangements</h2>

                <Bedrooms/>
            </div>
        </Layout>
    )
}

export default IndexPage
