import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Bedrooms = () => {
    const data = useStaticQuery(graphql`
    query bedrooms {
      allFile(filter: {relativeDirectory: {eq: "photos/bedrooms"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

    const images = data.allFile.edges

    const headerStyle = {
        marginTop: '20px',
        marginBottom: '2px'
    }

    return <div>
        <div className="columns">
            <div className="column" key={0}>
                <Img key={images[0].node.childImageSharp.fluid.src} fluid={images[2].node.childImageSharp.fluid}/>

                <h4 className={'subtitle is-4'} style={headerStyle}>Master bedroom</h4>
                <p>Ensuite bedroom with king bed</p>
            </div>
            <div className="column" key={1}>
                <Img key={images[2].node.childImageSharp.fluid.src} fluid={images[1].node.childImageSharp.fluid}/>

                <h4 className={'subtitle is-4'} style={headerStyle}>Bedroom 2</h4>
                <p>Ensuite bedroom, king bed</p>
            </div>
            <div className="column" key={2}>
                <Img key={images[0].node.childImageSharp.fluid.src} fluid={images[0].node.childImageSharp.fluid}/>

                <h4 className={'subtitle is-4'} style={headerStyle}>Bedroom 3</h4>
                <p>Ensuite bedroom, two single beds</p>
            </div>
        </div>
    </div>
}

export default Bedrooms
